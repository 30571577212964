
import React from 'react';
import { useState } from 'react';

import './App.css';

import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Account from './components/Account'
import Home from './components/Home';
import NavBar from './components/NavBar';

import TchatIndex from './components/Tchat/TchatIndex';
import PostCreate from './components/Posts/PostCreate';
import PostEdit from './components/Posts/PostEdit';
import { PostProvider } from './components/Contexts/PostContext'
import { MessageProvider } from "./components/Contexts/MessageContext";
import { ProtectedRoute } from './components/ProtectedRoutes/ProtectedRoutes';
import Dashboard from './components/Dashboard/Dashboard';

import { configureAbly, useChannel } from "@ably-labs/react-hooks";


function App() {

  configureAbly({ key: "jc3Mqw.Z9RSBQ:blU2Vj8UIX6g-nlbAuZgunrSrk3ar5FWMdHSijya6Ik", clientId: "1" });

  // const [channel] = useChannel("public:community", (message) => {
  //   console.log(message);
  // });

  const [messages, updateMessages] = useState([]);

  const [channel] = useChannel("public:community", (message) => {
    updateMessages((prev) => [...prev, message]);
  });

  // Convert the messages to list items to render in a react component
  const messagePreviews = messages.map((msg, index) => {
    //console.log(messages)
    return < li key={index} > {msg.data.message}</ li >
  });

  //console.log(messagePreviews)

  return (
    <React.Fragment>

      <PostProvider>
        <div className='bg-slate-100 min-h-screen'>

          <NavBar />
          <MessageProvider>
            <Routes>
              <Route path={'/'} element={<Home />} />
              <Route path={'/login'} element={<Login />} />
              <Route path={'/account'} element={<Account />} />

              <Route path={'/tchat'} element={<TchatIndex />} />

              <Route path={'/posts/create'} element={<PostCreate />} />
              <Route path={'/posts/:id/edit'} element={<PostEdit />} />
              <Route exact path='/dashboard' element={<ProtectedRoute />}>
                <Route exact path='/dashboard' element={<Dashboard />} />
              </Route>

              <Route path="*" element={<p>There's nothing here: 404!</p>} />
            </Routes>
          </MessageProvider>
          <div className="container mx-auto mt-4">
            <ul>
              {messagePreviews}
            </ul>
          </div>

        </div>
      </PostProvider>

    </React.Fragment>
  );
}



export default App;
