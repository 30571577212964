import React from "react";
import { NavLink } from 'react-router-dom';
import user from "../Models/user";
import { useNavigate } from "react-router-dom";
import './../App.css';




function NavBar() {

    const navigate = useNavigate();

    function logoutCallback() {
        navigate('/login');
    }
    const logout = () => {
        user.logout(logoutCallback)
    };

    if (!user.isLoggedIn()) {
        return (

            <div>
                <div className="bg-white">
                    <div className="pt-4 pb-4  flex justify-between container mx-auto">
                        <div className="flex">
                            <NavLink to='/' className="flex items-center p-2 bg-indigo-400 hover:bg-indigo-600 text-white rounded-md">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                </svg>
                                Home
                            </NavLink>
                            <NavLink to='/tchat' className="ml-4 p-2 bg-indigo-400 hover:bg-indigo-600 text-white rounded-md">Tchat</NavLink>
                        </div>
                        <div className="flex">
                            <NavLink className="ml-4 p-2 text-indigo-600 rounded-md" to='/login'>Login</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="bg-white">
                <div className="pt-4 pb-4  flex justify-between container mx-auto">
                    <div className="flex">
                        <NavLink to='/' className="flex items-center  p-2 bg-indigo-400 hover:bg-indigo-600 text-white rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>
                            Home
                        </NavLink>
                        <NavLink to='/tchat' className="ml-4 p-2 bg-indigo-400 hover:bg-indigo-600 text-white rounded-md">Tchat</NavLink>
                    </div>
                    <div className="flex">
                        <NavLink to='/account' className="flex ml-4 p-2 text-indigo-600 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                            Mon compte
                        </NavLink>
                        <span className="cursor-pointer ml-4 p-2 bg-indigo-400 hover:bg-indigo-600 text-white rounded-md" onClick={logout}>Logout</span>
                    </div>
                </div>
            </div>
        );
    }
};

export default NavBar;