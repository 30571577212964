import { useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../api/Network';
import Pusher from 'pusher-js';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {

    const initialFormValues = {
        username: "",
        message: ""
    }

    const [messages, setMessages] = useState([]);
    const [post, setPost] = useState([]);
    const [errors, setErrors] = useState({});
    const [formValues, setFormValues] = useState(initialFormValues);
    const navigate = useNavigate();
    const allMessages = [];

    const getMessages = async () => {
        const apiMessages = await API.get('/api/v1/messages');
        //console.log(apiMessages.data)
        setMessages(apiMessages.data.data);
    };

    const initPusher = () => {
        Pusher.logToConsole = true;

        const pusher = new Pusher('965f5735ac65c2cb8f8f', {
            cluster: 'eu'
        });
        //console.log(Pmsgs);
        const channel = pusher.subscribe('tchat-react');
        channel.bind('message', function (data) {
            allMessages.push(data)
            getMessages();
            //setMessages(allMessages)
            //navigate('/tchat');
            //console.log(data)
        });

    }

    // const getPost = async (id) => {
    //     const response = await API.get('/api/v1/Messages/' + id);
    //     const apiPost = response.data.data;
    //     setPost(apiPost);
    //     setFormValues({
    //         name: apiPost.name,
    //         slug: apiPost.slug
    //     })
    // };

    const sendMessage = async (e) => {
        e.preventDefault();
        try {
            await API.post('/api/v1/messages', {
                username: formValues['username'],
                message: formValues['message'],
            });
            setFormValues({ username: formValues['username'], message: "" })
            //console.log(allMessages)
            getMessages();
            setMessages(messages => [...messages, {
                username: formValues['username'],
                message: formValues['message'],
            }]);
            setErrors({});
            //setMessages({ ...messages, allMessages })
            //navigate('/tchat');


        } catch (err) {
            //console.log(err.response.data.errors)
            if (err.response.status == 422) {
                setErrors(err.response.data.errors);
                //console.log(err.response.data.errors)
                //return 'error'
            }
        }
    };

    // const updatePost = async (e) => {
    //     e.preventDefault();
    //     try {
    //         await API.put('/api/v1/Messages/' + post.id, formValues);
    //         setErrors({});
    //         setFormValues(initialFormValues)
    //         navigate('/Messages');
    //     } catch (err) {
    //         if (err.response.status == 422) {
    //             setErrors(err.response.data.errors)
    //         }
    //     }
    // }

    // const deletePost = async (id) => {
    //     if (!window.confirm('Are you sure ?')) {
    //         return;
    //     }
    //     await API.delete('/api/v1/Messages/' + id);
    //     getMessages();
    // }



    const onChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
    }

    return <MessageContext.Provider value={{ messages, getMessages, onChange, formValues, errors, initPusher, sendMessage }}>{children}</MessageContext.Provider>
}

export default MessageContext;