
import React from 'react';
import '../App.css';

function Home() {
    return (
        <React.Fragment>
            <div className='container mx-auto mt-10'>
                <h1 className='text-start font-bold text-2xl'>Homepage</h1>
                <div className="bg-white p-5 rounded-md mt-6">
                    Welcome to CRUD Laravel API rest with React JS
                </div>
            </div>


        </React.Fragment>
    );
}



export default Home;
