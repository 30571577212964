import React from "react";

import { useEffect, useContext, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import user from "../../Models/user";
import API from '../../api/Network';

import MessageContext from "../Contexts/MessageContext";

import '../../App.css';


function TchatIndex() {
    const bottomRef = useRef(null);
    const { messages, getMessages, formValues, onChange, errors, initPusher, sendMessage } = useContext(MessageContext)

    useEffect(() => {

        initPusher();
        getMessages();

        //console.log(Pmsgs)
    }, []);
    useEffect(() => {

        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);



    return (

        <div className="mt-10 container mx-auto">
            <div className="flex justify-between mb-6">
                <h1 className='text-start font-bold text-2xl'>Liste des message</h1>

                {user.isLoggedIn() ? <Link to="/posts/create" className="px-4 py-2 bg-violet-400 hover:bg-violet-600 text-white rounded-md">Nouveau post</Link> : ''}
            </div>

            <div className="relative overflow-x-auto mt-6">

                <div className="container mx-auto mt-4">
                    <div className="flex flex-col">
                        <div className="flex items-center mb-3">
                            <p className="mb-0 mr-2 font-bold">Ton nom :</p>
                            <input className="px-3 py-2 shadow-md rounded-md" name="username" value={formValues['username']} onChange={onChange}></input>
                        </div>

                        {errors.username && <span className="text-xs text-red-600">{errors.username[0]}</span>}
                    </div>


                    <div id="chat" className="p-5 bg-white w-100 rounded-md mt-3 h-[300px] overflow-y-scroll chatContent">
                        {messages.map((msg, index) => {
                            return (
                                <div key={index} className="wrapperChat flex flex-col">
                                    <div className="wrapperUsername">
                                        <p className="font-bold">{msg.username}</p>
                                    </div>
                                    <div className="wrapperMessage">
                                        <span className="text-regular">{msg.message}</span>
                                    </div>
                                </div>
                            )
                        })}
                        <div ref={bottomRef} />
                    </div>
                    <form onSubmit={sendMessage} className="flex mt-4 mb-10">
                        <div className="flex flex-col w-full">
                            <input name="message" className="w-full px-3 py-2 rounded-md" value={formValues['message']} onChange={onChange} />
                            {errors.message && <span className="text-xs text-red-600 mt-2">{errors.message[0]}</span>}
                        </div>

                        <button type="submit" className="px-4 py-2 bg-indigo-400 self-start hover:bg-indigo-600 text-white font-bold ml-4 rounded-md">Envoyer</button>
                    </form>
                    {/* <ul>
                        {messages ? messages.map((message) => {
                            return (
                                <li key={message.id}>{message.message}</li>
                            )
                        }
                        ) : ''}
                    </ul> */}
                </div>
            </div>
        </div >

    );

};

export default TchatIndex;