import React from "react";
import { useState } from 'react';
import API from '../api/Network';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Button from '../FormComponents/Button'
import user from "../Models/user";



function Login() {

    const [email, setEmail] = useState([]);
    const [password, setPassword] = useState([]);
    const [toHome] = React.useState(false);
    const [authError, setAuthError] = React.useState(false);
    const [unknownError, setUnknownError] = React.useState(false);

    let navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setAuthError(false);
        setUnknownError(false);
        function authenticatedCallback() {

            let { from } = { from: { pathname: '/dashboard' } }
            navigate(from)
        }
        API.get('/sanctum/csrf-cookie')
            .then(response => {
                API.post('/api/login', {
                    email: email,
                    password: password
                },).then(response => {
                    user.authenticated(response.data, authenticatedCallback)
                }).catch(error => {
                    if (error.response && error.response.status === 422) {
                        setAuthError(true);
                    } else {
                        setUnknownError(true);
                        console.error(error);
                    }
                });
            });
    }

    // const onChangehandler = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     let data = {};
    //     data[name] = value;
    //     this.setState(data);
    // };

    if (toHome === true) {
        return <Navigate to='/account' />
    }
    return (
        <div className="mt-6 p-5 bg-white rounded-md container mx-auto">
            <h1 className="text-center font-bold text-2xl">Login</h1>
            <form onSubmit={handleSubmit} className="w-1/2 m-auto">
                <div className="form-group">
                    {/* <Input placeholder="email" name="email" type="email" onChange={this.changeTitle.bind(this)} /> */}
                    <div className="relative my-6">
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                            onChange={e => setEmail(e.target.value)}
                        />
                        <label
                            htmlFor="email"
                            className="absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\ua0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                        >
                            Email
                        </label>
                    </div>
                </div>

                <div className="form-group">
                    {/* <Input placeholder="password" name="password" type="password" onChange={e => setPassword(e.target.value)} value={password} /> */}
                    <div className="relative my-6">
                        <input
                            id="password"
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                            onChange={e => setPassword(e.target.value)}
                        />
                        <label
                            htmlFor="password"
                            className="absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\ua0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                        >
                            Password
                        </label>
                    </div>
                </div>
                {authError ? <div className="alert alert-danger">Credentials not recognised. Please try again.</div> : null}
                {unknownError ? <div className="alert alert-danger">There was an error submitting your details.</div> : null}
                <div className="flex justify-center">
                    <Button type="submit" />
                </div>

            </form>
        </div>
    );
};

export default Login;
