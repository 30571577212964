import React from "react";
import { useState } from 'react';
import user from "../Models/user";

import '../App.css';
import { Navigate } from 'react-router-dom';



function Account() {
    const [authenticated] = useState(localStorage.getItem("authenticated"));

    if (!authenticated) {
        return <Navigate replace to="/login" />;
    } else {
        return (
            <div className="container mx-auto mt-10">
                <h1 className='text-center font-bold text-2xl'>Mon compte</h1>
                <div className="bg-white p-5 rounded-md mt-6">
                    <p>Welcome {user.name} - {user.id}, <br />
                        CRUD Laravel API Rest & React JS<br />
                        <span className="font-bold">2023 - KPDEV</span>
                    </p>
                </div>

            </div>
        );
    }
};

export default Account;